import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { getSubTasks } from "./getSubtask";
import { logEntry } from "../../logEntry";

export async function addSubTask(activities,state, dispatch, getUser, mainTaskId, moduleId,assignedTo,targetDate, handleClose,SUBTASK_ADD, MODULE, MAINTASK, SUBTASK, project_id) {
  dispatch(isLoading());
//   if (state.targetDate.value  > targetDate && targetDate !== '0001-01-01') {
//     Alert("warning", `Target date should not exceed ${MAINTASK} target date`)
//     dispatch(isLoaded());
//   }
//  else if (state.targetDate.value < Moment(Date.now()).format("YYYY-MM-DD")  && targetDate !== '0001-01-01') {
//     dispatch(isLoaded());
//     Alert('warning', 'Select valid date');

//   }
//   else 
  if (state.targetDate.value !== "" && state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "" ) {
    // let EstHours = Number(state.days.value * 24) + Number(state.hours.value);
    if( state.userSelected === 0 || state.userSelected ===''){
      Alert('warning',"Invalid User");

    }else{
      try {
        const start_time=Date.now();
        var response = await API.post("manage_tasks.php", {
          assignedBy: getUser.empId,
          assignedTo: state.userSelected === "" ? assignedTo : state.userSelected,
          "projectId": (project_id || getUser.projectId),
          maintaskId: mainTaskId,
          moduleId: moduleId,
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          targetDate:state.targetDate.value,
          acceptanceCriteria: state.acceptanceCriteria.value,
          message: `${SUBTASK} is added to ${MAINTASK}`
        }, {}, false);
        const end_time=Date.now();
      
        if (response.status === 'true') {
          dispatch(getSubTasks(dispatch, getUser, mainTaskId))
          activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
            sub_activity:"Create",
            response_time : (end_time-start_time), task_meta_data:{story_id:mainTaskId}    
          },getUser)
          Alert("success", SUBTASK_ADD);
        }
        
        else{
          // Alert('warning', `${MAINTASK} should be assigned to ${MODULE} before adding ${SUBTASK}`);
          Alert('warning', `${SUBTASK} Already Existed!`);
  
        }
      } catch (error) {
        Alert('warning', error.message);
           //error log
      activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"Create",
      error_message:error.message
    },getUser)
        dispatch(isLoaded());
      }
      handleClose()
    }
 
  } else {
    Alert("warning", `Please give ${SUBTASK} information`)
  }
}

export async function addAllSubTask(activities,state, dispatch, getUser, mainTaskId, moduleId,assignedTo,targetDate, handleClose,SUBTASK_ADD, MODULE, MAINTASK, SUBTASK, project_id) {
  dispatch(isLoading());
//   if (state.targetDate.value  > targetDate && targetDate !== '0001-01-01') {
//     Alert("warning", `Target date should not exceed ${MAINTASK} target date`)
//     dispatch(isLoaded());
//   }
//  else if (state.targetDate.value < Moment(Date.now()).format("YYYY-MM-DD")  && targetDate !== '0001-01-01') {
//     dispatch(isLoaded());
//     Alert('warning', 'Select valid date');

//   }
//   else 

console.log("im here")

  if (state.targetDate.value !== "" && state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "" ) {
    // let EstHours = Number(state.days.value * 24) + Number(state.hours.value);
    // if( state.userSelected === 0 || state.userSelected ===''){
    //   Alert('warning',"Invalid User");

    // }else{
      try {
        const start_time=Date.now();
        var response = await API.post("manage_tasks.php", {
          assignedBy: getUser.empId,
          assignedTo: assignedTo,
          "projectId": (project_id || getUser.projectId),
          maintaskId: mainTaskId,
          moduleId: moduleId,
          crop: getUser.corp,
          action: "add",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          targetDate:state.targetDate.value,
          acceptanceCriteria: state.acceptanceCriteria.value,
          message: `${SUBTASK} is added to ${MAINTASK}`
        }, {}, false);
        const end_time=Date.now();
      
        if (response.status === 'true') {
          // dispatch(getSubTasks(dispatch, getUser, mainTaskId))
          activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
            sub_activity:"Create",
            response_time : (end_time-start_time), task_meta_data:{story_id:mainTaskId}    
          },getUser)
          // Alert("success", SUBTASK_ADD);
        }
        
        else{
          // Alert('warning', `${MAINTASK} should be assigned to ${MODULE} before adding ${SUBTASK}`);
          Alert('warning', `${SUBTASK} Already Existed!`);
  
        }
      } catch (error) {
        Alert('warning', error.message);
           //error log
      activities && logEntry({user_id:getUser.userName,logging_level:2,activity_id:activities["Task Management"],
      sub_activity:"Create",
      error_message:error.message
    },getUser)
        dispatch(isLoaded());
      }
      handleClose()
    // }
 
  } else {
    Alert("warning", `Please give ${SUBTASK} information`)
  }
}