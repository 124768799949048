import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import { moduleReducer, initialState } from "./moduleReducer";
import { getSubStringId } from "../../Common/SubStringConvert";
import { useWebProperties } from '../../Common/webProperties';
import { isMobile } from 'react-device-detect';
import API from '../../Common/Network/API';
import { updateUserStory } from './Services/updateUserStory';
import { updateUserStoryToKanban } from "./Services/updateUserStoryToKanban";
import Axios from 'axios';
import { Reports_URL } from '../../Common/config';
import RootLoader from '../../Common/Loader/RootLoader';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function CompleteReason(props) {

    const getUser = useSelector(state => state.auth)
    const [taskData, setTaskData] = useState([])
    const [taskDescription, setTaskDescription] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const { MAINTASK, TASKS_WARNING, ROADBLOCK_WARNING, AI_MODEL } = useWebProperties();
    // eslint-disable-next-line
    const [state, dispatch] = useReducer(moduleReducer, initialState);
    const classNames = useStyles();
    const [loader, setLoader] = useState(false)
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});
    
    const activities = useSelector(state => state.landingReducer.userActivities)


    useEffect(() => {
        getTaskInfoWithId()
        // eslint-disable-next-line
    }, [])

    const getTaskInfoWithId = async () => {
        try {
            const response = await API.post(
                "getUpdateSprint.php",
                {
                    action: "getTaskInfoWithId",
                    story_us_id: props.data.taskId,
                    crop: getUser.corp,
                },
                {},
                false
            );
            if (response.status === "True") {
                setTaskData(response.data)
                await getCompleteResons(response.data)
            } else {
                setTaskData([])
            }
        } catch (error) {
            console.log("error", error.message);
        }
    }

    const getCompleteResons=async(data)=>{
        setLoader(true)
        try {
          var response = await Axios.post(`${Reports_URL}/ai/getTaskCompletionQuestions`, {
            "acceptance_criteria": data[0].acceptance_criteria,
            "role":"Developer",
            "model": AI_MODEL
          }, {}, false);
          const questionData=response.data.questions.map((q,index) => {
            return {id:index,question:q.question, answer:q.default_answer}
          })
          setQuestions(questionData)
          if (Array.isArray(questionData)){
            const result=questionData.reduce((acc, q) => {
                acc[q.id] = q.answer ? q.answer : '';
                return acc;
              }, {})
            setAnswers(result)
          }
          setLoader(false)
        } catch (error) {
          setLoader(false)
          console.log(error)
        }
      }

    const handleInputChange = (id, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [id]: value,
        }));
    };

    const validateAnswers = () => {
        const newErrors = {};
        questions.forEach((q) => {
            if (!answers[q.id]) {
            newErrors[q.id] = `* Required field`;
            }
        });
        return newErrors;
    };

    const completeTaskWithQuestions = async () => {
        const validationErrors = validateAnswers();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }else{
            let description=''
            questions.forEach((q) => {
                description+=`${answers[q.id]},`
            })
            let message = `${description}, ${MAINTASK} is completed`;
            if (props.data.board === 'kanbanBoard') {
                updateUserStoryToKanban(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,props.data.activities);
            } else {
                updateUserStory(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities);
            }
        }
    }

    const completeTask = () => {
        setLoader(true)
        if (taskDescription === null) {
            setErrorMessage(`Please enter complete reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            let message = `${taskDescription}, ${MAINTASK} is completed`;
            if (props.data.board === 'kanbanBoard') {
                updateUserStoryToKanban(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,props.data.activities);
            } else {
                updateUserStory(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING,activities);
            }
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className={isMobile ? `modal-content p-2` : `modal-content col-5 p-2`} style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    {
                                        taskData.length > 0 &&
                                        <h5 class="modal-title p-2">{getSubStringId(taskData[0]?.project_name, 2)}{'-'}
                                            {taskData[0]?.main_task_id !== null && taskData[0]?.main_task_id !== "0" ? `${taskData[0]?.main_task_id}-` : ''}
                                            {taskData[0]?.us_id}-{taskData[0]?.story_title}</h5>
                                    }
                                </div>
                                {loader?<RootLoader />:
                                <div>
                                    {questions.length>0?
                                    <div>
                                        {questions?.map((q) => (
                                            <div key={q.id} className="question">
                                            <label>{q.id+1}.{q.question}</label>
                                            <textarea
                                                className='form-control'
                                                value={answers[q.id] || ''}
                                                onChange={(e) => handleInputChange(q.id, e.target.value)}
                                            />
                                            {/* Display error message if exists */}
                                            {errors[q.id] && <p style={{ color: 'red' }}>{errors[q.id]}</p>}
                                            </div>
                                        ))}
                                    </div>:
                                    <div className="modal-body">
                                        <label>Complete Reason</label>
                                        <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                        {errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}
                                    </div>}
                                </div>}
                                <div class="modal-footer justify-space-between">
                                    <button type="button" class="btn btn-outline-danger" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Cancel</button>

                                    <button disabled={loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={() => {
                                        if(questions.length>0){
                                            completeTaskWithQuestions()
                                        }else{
                                            completeTask()
                                        }
                                    }}  >Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
