import React, { useEffect, useReducer, useState } from "react";

import TopNav from "../Utility/TopNav";
import SideBar from "../Utility/SideNav";
import { getPriorityTasks } from "../ConsolidatedToDo/Services/getConsolidatedToDo";
import { useSelector } from "react-redux";
import { consolidatedToDoReducer, initialState } from "../ConsolidatedToDo/reducer";
import { getAllUserStoryMessages } from "./Services/getAllUserStoryMessages";
import { moduleReducer, initialState as chatInitialState } from "../../Common/Modules/moduleReducer";
import { getPriorityById, MESSAGE_REFRESH_TIMER } from "../../Common/commonUsage";
import { useWebProperties } from "../../Common/webProperties";
import MainTaskChatBox from "../../Common/ChatMainTask";
import TaskInfo from "../UserDashboard/taskInfo";
import { getSubStringId } from "../../Common/SubStringConvert";
import MainTaskInfo from "../../Common/TasksModals/mainTaskInfo";

export default function UserSprints() {


  const getUser = useSelector(state => state.auth);
  const [state, dispatch] = useReducer(consolidatedToDoReducer, initialState)
  const [chatState, dispatch2] = useReducer(moduleReducer, chatInitialState)

  const [open, setOpen] = useState({ status: false, index: 0 })
  const [cardInfo, setCardInfo] = useState()
  //eslint-disable-next-line
  const [loader, setLoader] = useState({
    loader: false,
    index: 0
  })

  const [searchWord, setSearchWord] = useState('');
  const [filteredTasks, setFilteredTasks] = useState([]);


  const { CHAT, MAINTASK, COMMENTS } = useWebProperties();

  useEffect(() => {
    getPriorityTasks(dispatch, getUser.user);
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    setFilteredTasks(state.priorityTasks);
  }, [state.priorityTasks]); // Sync filteredTasks when state updates


  useEffect(() => {
    const fetchUserStoryMessages = () => {
      getAllUserStoryMessages(dispatch2, getUser.user);
    };

    // Function to fetch messages and schedule the next fetch after 10 seconds
    const fetchDataAndScheduleNext = () => {
      fetchUserStoryMessages();
      setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER); // Schedule next fetch after 10 seconds
    };

    // Initial fetch when component mounts
    fetchUserStoryMessages();

    // Schedule the next fetch after 10 seconds
    const timerId = setTimeout(fetchDataAndScheduleNext, MESSAGE_REFRESH_TIMER);

    // Clean up the timer on component unmount
    return () => clearTimeout(timerId);
    // eslint-disable-next-line
  }, []);

  const getMessagesCount = (data, msg, task, empId, followUp = null,) => {
    let task_id
    if (task.task_type === 'main_task') {
      task_id = task.us_id
    } else {
      task_id = `${task.main_task_id}-${task.t_id}`
    }
    const msgCount = msg?.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task_id).map((messages, i) => {
      // eslint-disable-next-line
      return i, messages
    })
    //for follow up check
    if (followUp === 'Follow Up') {
      return <div style={{ backgroundColor: 'teal', cursor: 'pointer', color: 'white', marginLeft: 5, marginTop: 5, marginBottom: 5, width: '80px', textAlign: 'center', height: 'fit-content', padding: '5px' }} title="Follow Up"
        onClick={(event) => handleChatOpen("maintask", task, msgCount, followUp)}>{followUp}</div>
    }

    return (
      <i>
        {msgCount?.length > 0 ?
          <div style={{ display: 'flex', marginRight: '-8px', width: '40px' }}>
            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
              onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-4px' }}>{msgCount?.length > 9 ? "+9" : msgCount?.length}</span>
          </div>
          :
          <div style={{ display: 'flex', marginRight: '-8px', width: '40px' }}>
            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px' }}
              onClick={(event) => handleChatOpen("maintask", task, msgCount)} />
          </div>}
      </i>
    )
  };

  const handleChatOpen = (action, index, sno, followUp = null) => {
    var info;
    if (index.task_type === 'sub_task') {
      setOpen({ status: true, action: 'workflow_task' });
      info = {
        action: 'workflow_task', id: index.main_task_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
        story_type: index.story_type, us_id: index.us_id, followUp: followUp, subtask_id: index.t_id, meetingUrl: index.meeting_url, taskTitle: index.task_title
      }
    } else {
      setOpen({ status: true, action: action });
      if (action === "maintask") {
        info = {
          action: action, id: index.us_id, sno: sno, project_name: index.project_name, project_id: index.project_id, main_task_id: index.main_task_id,
          story_type: index.story_type, us_id: index.us_id, followUp: followUp, group_task_type: index.group_task_type, meetingUrl: index.meeting_url
        }
      }
    }
    setCardInfo(info);
  }

  const handleOpen = (action, index, data) => {
    var info
    setOpen({ status: true, index: index, action: action });
    if (action === "taskInfo") {
      var view_status = "taskInfo"
      info = {
        main_task_id: index.main_task_id,
        view: view_status,
        us_id: index.us_id,
        projectName: index.epic_name, //epic/module name
        epicId: index.epic_id,
        moduleName: index.sprint_desc,
        moduleId: index.sprint_id,
        title: index.story_title,
        description: index.story_desc,
        taskId: index.story_id,
        createdDate: index.created_date,
        taskProgress: index.story_status,
        storyPoints: index.story_points,
        acceptanceCriteria: index.acceptance_criteria,
        assignedTo: index.assignedto,
        assignedBy: index.assignedby,
        completedDate: index.modified_date,
        completedStatus: index.complete_status,
        targetDate: index.target_date,
        device_id: index.device_id,
        project_name: index.project_name,
        story_type: index.story_type,
        task_type: index.task_type,
        startDate: index?.starttime,
        endDate: index?.endtime,
        calendarId: index?.calendar_id,
        projectId: index?.project_id,
        acknowledgement: index?.acknowledge ? JSON.parse(index?.acknowledge) : null
      }
    }
    else if (action === "addToProject") {
      var status = "backlog_addUser"
      info = { view: status, data: data }
    }
    else if (action === "addUserStory") {
      info = { view: action, data: data }
    } else if (action === "modify") {
      status = "modify"
      info = { view: status, data: data }
    }
    else if (action === "delete") {
      status = "delete"
      info = { view: status, data: data }
    }
    else if (action === "complete") {
      status = "complete"
      info = { view: status, data: data }
    } else if (action === "private_todo_taskInfo") {
      view_status = "private_todo_taskInfo"
      info = {
        view: view_status,
        pt_id: index.pt_id,
        priority_level: index.priority_level,
        title: index.story_title,
        description: index.story_desc,
        storyPoints: index.story_points,
        acceptanceCriteria: index.acceptance_criteria,
        assignedBy: index.username,
        personal_todo_status: index.personal_todo_status,
      }
    } else if (action === "addSubtask") {
      info = {
        mainTaskId: index.story_id,
        action: action, moduleId: index.sprint_id,
        ideaId: index.epic_id,
        assignedTo: index.assignedTo,
        us_id: index.us_id,
        title: index.story_title,
        projectName: index.project_name,
        template_story_id: index.template_story_id,
        is_workflow: index.is_workflow,
        project_id: index.project_id
      }
    }
    else if (action === "modifyTask" || action === 'delegate') {
      info = {
        moduleId: index.sprint_id,
        moduleName: index.sprint_desc,
        priorityLevel: index.priority_level,
        projectId: index.project_id,
        ideaId: index.epic_id,
        project_name: index.project_name,
        projectName: index.sprint_desc,
        title: index.story_title,
        description: index.story_desc,
        taskId: index.task_type === 'sub_task' ? index.task_id : index.story_id,
        acceptanceCriteria: index.acceptance_criteria,
        storyPoints: index.story_points,
        userDetails: index.assignedto,
        id: index.assignedTo,
        targetDate: index.target_date,
        story_type: index.story_type,
        kanban_status: index.kanban_status,
        tasks: index,
        task_type: index.task_type,
        active_status: index.active_status,
        us_id: index.us_id,
        role: index.role,
        assignedBy: index.assigned_by
      }
    }
    else if (action === "modifySubTask") {
      info = {
        mainTaskId: index.story_id,
        subTaskId: index.task_id,
        taskTitle: index.story_title,
        taskDesc: index.story_desc,
        estimatedHours: index.estimatedHours,
        us_id: index.us_id,
        t_id: index.t_id,
        action: action,
        ideaId: '',
        acceptanceCriteria: index.acceptance_criteria,
        targetDate: index.target_date,
        userDetails: index.assignedto,
        id: index.assignedTo,
        priorityLevel: index.priority
      }
    }
    else if (action === "add") {
      status = "backlog_addUser"
      info = {
        view: status,
      }
    }
    else if (action === "changeUserstoryStatus") {
      info = {
        action: action,
        inprogress: data.inprogressStoryId,
        todo: data.todoStoryId,
        currentSprint: data.currentSprint,
        assignedTo: data.assignedTo,
        projectName: data.projectName,
        story_title: data.story_title,
        inprogress_story_title: data.inprogress_story_title,
        us_id: data.us_id,
        todo_us_id: data.todo_us_id,
        toDoProjectName: data.toDoProjectName,
        task_type: data.task_type,
        inprogress_task_type: data.inprogress_task_type,
        meeting_url: data.meetingUrl,
      }
    }
    else if (action === "add_to_epic") {
      var epic_status = "epic"
      info = {
        us_id: index.us_id,
        view: epic_status,
        projectid: index.project_id,
        taskId: index.story_id,
        project_id: index.project_id,
      }
    }
    else if (action === "current_add_to_sprint") {
      info = {
        project_id: index.project_id,
        us_id: index.us_id,
        projectid: index.project_id,
        taskId: index.story_id,
        // currentDate: data.currentDate,
        assignedTo: index.assignedto,
        assign_to: index.assignedTo,
        title: index.story_title,
        sprintDesc: index.sprint_desc,
        // device_id: index.device_id,
        // player_id: index.player_id,
        storyPoints: index.story_points,
      }
    }
    else if (action === "Delete") {
      info = {
        projectName: index.projectitle,
        title: index.story_title,
        description: index.story_desc,
        taskId: index.story_id,
        moduleId: index.sprint_id,
        action: action
      }
    } else if (action === "movetobacklog") {
      let message = `${MAINTASK} is blocked`;
      info = {
        taskId: index.story_id,
        message: message,
        project_id: index.project_id,
        sprintId: index.sprint_id,
        kanban_status: index.kanban_status === "1" ? "1" : "0"
      }
    } else if (action === "commentInfo") {
      info = {
        title: index.title,
        description: index.description,
        us_id: index.us_id,
        id: index.id,
        mainTaskId: index.mainTaskId,
        mainTaskTitle: index.mainTaskTitle,
        dependencyId: index.dependencyId,
        status: index.complete_status,
        modifiedby: index.modifiedby,
        timeLeft: index.timeLeft,
        extraHours: index.extraHours,
        taskDesc: index.taskDesc,
        ideaTitle: index.epic_name,
        laneId: index.laneId,
        moduleDesc: index.moduleDesc,
        storyPoints: index.story_points,
        acceptanceCriteria: index.acceptanceCriteria,
        assignedDate: index.assignedDate,
        targetDate: index.targetDate,
        modifiedDate: index.modifiedDate,
        action: action,
        openStatus: true,
        task_end_date: index.task_end_date,
        assignedto: index.assignedto,
        assignedby: index.assignedby,
        road_block_ids: index.road_block_ids,
        combine_tasks: index.combine_tasks,
        blocking_id: index.blocking_id,
        from_device: index.from_device,
        task_count: index.task_count,
        sub_tasks: index.sub_tasks
      }
    }
    else if (action === "taskCommentInfo") {
      info = {
        title: index.title,
        description: index.description,
        us_id: index.us_id,
        id: index.id,
        mainTaskId: index.mainTaskId,
        mainTaskTitle: index.mainTaskTitle,
        dependencyId: index.dependencyId,
        status: index.complete_status,
        modifiedby: index.modifiedby,
        timeLeft: index.timeLeft,
        extraHours: index.extraHours,
        taskDesc: index.taskDesc,
        ideaTitle: index.epic_name,
        laneId: index.laneId,
        moduleDesc: index.moduleDesc,
        storyPoints: index.story_points,
        acceptanceCriteria: index.acceptanceCriteria,
        assignedDate: index.assignedDate,
        targetDate: index.targetDate,
        modifiedDate: index.modifiedDate,
        action: action,
        openStatus: true,
        task_end_date: index.task_end_date,
        assignedto: index.assignedto,
        assignedby: index.assignedby,
        road_block_ids: index.road_block_ids,
        combine_tasks: index.combine_tasks,
        blocking_id: index.blocking_id,
        from_device: index.from_device,
        task_count: index.task_count,
        sub_tasks: index.sub_tasks
      }
    }

    setCardInfo(info)
  };

  const handleChatClose = () => {
    setOpen({ status: false, index: 0 })
    getAllUserStoryMessages(dispatch2, getUser.user);
  };

  const handleClose = () => {
    const fetchLoad = async () => {
      setOpen({ status: false, index: 0 })
      await getPriorityTasks(dispatch, getUser.user);

      setLoader({ loader: false, index: 0 })
    }
    fetchLoad()
  };
  const handleModalClose = () => {
    setOpen({ status: false, index: 0 })
  };

  const getOnlyCount = (data, msg, task, empId) => {
    let task_id
    if (task.task_type === 'main_task') {
      task_id = task.us_id
    } else {
      task_id = `${task.main_task_id}-${task.t_id}`
    }

    const msgCount = msg?.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === task_id).map((messages, i) => {
      // eslint-disable-next-line
      return i, messages
    })
    return msgCount?.length
  };

  const handleSearch = (searchValue) => {
    setSearchWord(searchValue);

    if (!searchValue) {
      setFilteredTasks(state.priorityTasks); // Reset if search is empty
      return;
    }

    const lowerCaseSearch = searchValue.toLowerCase();

    const filteredData = state.priorityTasks.filter(task =>
      task.us_id.toLowerCase().includes(lowerCaseSearch) ||
      task.story_title.toLowerCase().includes(lowerCaseSearch)
    );

    setFilteredTasks(filteredData);
  };

  return (
    <div className="container-scroller">
      <TopNav />
      <div className="container-fluid page-body-wrapper">
        <SideBar />
        <div className="main-panel">
          <div className="p-5">
            <input type="search" class="form-control" style={{ backgroundColor: 'transparent', borderBottom: '2px solid black', borderTop: '2px solid black', borderLeft: '12x solid black', borderRight: '2px solid black', marginTop: '-5px', width: 250, height: '32px', marginRight: '17%' }}
              value={searchWord}
              onChange={(event) => handleSearch(event.target.value)}
              placeholder="Search with id or title"
            />

            <table className="table table-sm table-bordered" style={{ marginTop: '20px', width: '100%' }}>
              <thead>
                <tr>
                  <th>Task Details</th>
                  <th>Chat</th>
                  <th>Activity Log</th>
                  <th>Assigned to</th>
                  <th>Action</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredTasks.length === 0 ? <tr><p>No Data Found</p></tr> :
                    filteredTasks.map((task, index) => {
                      return (
                        <tr key={index}>
                          <td onClick={() => handleOpen("taskInfo", task)}>{
                            task.status === 'completed' ?
                              <del>
                                <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, task, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(task.project_name, 3)}{'-'}{task.main_task_id !== null && task.main_task_id !== "0" ? `${task.main_task_id}-` : ''}{task.us_id}{'-'}{task.story_title}{task.blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(task.project_name, 3)}{'-'}{task.userStoryId}</span>}</p> </b>

                              </del>
                              :
                              <b style={{ cursor: 'pointer' }} > <p style={{ fontWeight: getOnlyCount(index, chatState.allMessages, task, getUser.user.empId) ? 'bold' : 'normal', textTransform: 'capitalize' }}>{getSubStringId(task.project_name, 3)}{'-'}{task.main_task_id !== null && task.main_task_id !== "0" ? `${task.main_task_id}-` : ''}{task.us_id}{'-'}{task.story_title}{task.blocked_id !== null && <span style={{ width: '100px', cursor: 'pointer', fontWeight: 'bold', color: 'white', backgroundColor: 'rgb(255, 170, 51)', padding: '3px 5px', marginLeft: 5, textAlign: 'center' }} >{getSubStringId(task.project_name, 3)}{'-'}{task.userStoryId}</span>}</p> </b>
                          }</td>
                          <td>
                            {
                              getMessagesCount(index, chatState.allMessages, task, getUser.user.empId)
                            }
                          </td>
                          <td>
                            <button
                              style={{
                                border: "0",
                                width: "8px",
                                height: "30px",
                                backgroundColor: "transparent",
                              }}
                              type="button"
                              onClick={() =>
                                handleOpen(
                                  // eslint-disable-next-line
                                  task.task_type !== 'sub_task' ? "commentInfo" : "taskCommentInfo",
                                  {
                                    title: task.title,
                                    subTaskDesc: task.subTaskDesc,
                                    us_id: task.us_id,
                                    id: task.id,
                                    main_task_id: task.main_task_id,
                                    dependencyId: task.dependencyId,
                                    timeLeft: task.timeLeft,
                                    complete_status: task.complete_status,
                                    modifiedby: task.modifiedby,
                                    extraHours: task.extraHours,
                                    mainTaskTitle: task.mainTaskTitle,
                                    taskDesc: task.taskDesc,
                                    epic_name: task.epic_name,
                                    laneId: task.laneId,
                                    moduleDesc: task.moduleDesc,
                                    story_points: task.story_points,
                                    acceptanceCriteria: task.acceptanceCriteria,
                                    assignedDate: task.assignedDate,
                                    targetDate: task.targetDate,
                                    modifiedDate: task.modifiedDate,
                                    action: task.task_type !== 'sub_task' ? "commentInfo" : "taskCommentInfo",
                                    task_end_date: task.task_end_date,
                                    road_block_ids: task.road_block_ids,
                                    combine_tasks: task.combine_tasks,
                                    blocking_id: task.blocking_id,
                                    from_device: task.from_device
                                  })}
                            >
                              <img
                                src="images/common/comments.svg"
                                alt="logo"
                                title={COMMENTS}
                                style={{ width: "20px", height: "25px", marginLeft: "-10px", marginTop: "-9px" }}
                              />
                            </button>
                          </td>
                          <td>{task.assignedto}</td>
                          <td>{getMessagesCount(index, chatState.allMessages, task, getUser.user.empId, 'Follow Up')}</td>
                          <td>{getPriorityById(task.priority_level)}</td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>
          </div>
        </div>
        {
          (open.action === "taskInfo") ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
          /> : null
        }
        {
          open.action === "maintask" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleChatClose}
          /> : null
        }
        {
          open.status && open.action === 'commentInfo' ?
            <TaskInfo
              open={open}
              handleClose={handleModalClose}
              data={cardInfo}
              handleModalClose={handleModalClose} /> : null
        }
      </div>
    </div>
  );
}
