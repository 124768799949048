import React from 'react'
import '../../../Authentication/LandingPage/style.scss';
import { useSelector } from 'react-redux';

export default function PrivacyRallyNow() {

    const webProperties = useSelector(state => state.landingReducer.webProperties)
    return (
        <>
            <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{backgroundColor: 'white'}}>
                <button style={{background: 'white'}} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    {/* eslint-disable-next-line */}
                    <a className="navbar-brand">
                        {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
                        <img className="agile-supportlogo" src="images/common/rallynow.png" width="110" alt="" />

                    </a>
                </div>
            </nav>
            <div className='container ppc'>
            <h1>Privacy Policy</h1>
            <p>Last updated: March 14, 2025</p>
<p>Welcome to RallyNow! Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services. By using RallyNow, you agree to the terms outlined in this Privacy Policy.</p>

<h2>1. Information We Collect</h2>
<p>When you use RallyNow, we may collect the following information:</p>
<ul>
    <li><strong>Personal Information:</strong> Name, email address, and other identifiers you provide when creating an account.</li>
    <li><strong>Usage Data:</strong> Information about your interactions with the app, such as call duration, timestamps, and meeting participation.</li>
    <li><strong>Device Information:</strong> IP address, device type, operating system, and browser type.</li>
    <li><strong>Media and Communications Data:</strong> Audio, video, and chat messages transmitted during calls.</li>
    <li><strong>Optional Data:</strong> Additional data you may provide, such as feedback or support requests.</li>
</ul>

<h2>2. How We Use Your Information</h2>
<p>We use your information to:</p>
<ul>
    <li>Provide, operate, and improve RallyNow services.</li>
    <li>Facilitate communication between users.</li>
    <li>Maintain security and prevent fraud.</li>
    <li>Comply with legal obligations.</li>
    <li>Enhance user experience through analytics and troubleshooting.</li>
</ul>

<h2>3. Sharing of Information</h2>
<p>We do not sell or rent your personal data. However, we may share your information with:</p>
<ul>
    <li><strong>Service Providers:</strong> Third-party vendors assisting in service operations (e.g., hosting, analytics, security).</li>
    <li><strong>Legal Authorities:</strong> If required by law or to protect legal rights.</li>
    <li><strong>Other Users:</strong> Meeting participants may see your name, profile details, and shared media during calls.</li>
</ul>

<h2>4. Data Security</h2>
<p>We implement appropriate security measures to protect your data. However, no method of transmission over the internet is 100% secure. We encourage you to take precautions when sharing sensitive information.</p>

<h2>5. Data Retention</h2>
<p>We retain your data only for as long as necessary to provide services or comply with legal obligations. You may request data deletion by contacting us at <strong>[Insert Contact Email]</strong>.</p>

<h2>6. Your Rights and Choices</h2>
<p>Depending on your location, you may have the right to:</p>
<ul>
    <li>Access, update, or delete your data.</li>
    <li>Restrict or object to data processing.</li>
    <li>Withdraw consent where applicable.</li>
</ul>
<p>To exercise these rights, contact us at <strong>support@task24X7.com</strong>.</p>

<h2>7. Third-Party Services</h2>
<p>RallyNow may integrate with third-party services (e.g., Jitsi Meet) that have their own privacy policies. We encourage you to review their policies separately.</p>

<h2>8. Children’s Privacy</h2>
<p>RallyNow is not intended for children under 13 (or the applicable age in your region). We do not knowingly collect personal information from children.</p>

<h2>9. Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy periodically. Changes will be posted with a revised effective date. Continued use of RallyNow signifies your acceptance of the updated policy.</p>

<h2>10. Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us at <strong> support@task24X7.com</strong>.</p>
            </div>
            <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
                <div style={{ color: 'white' }}>
                    RallyNow © Copyright {new Date().getFullYear()}. All Rights Reserved.
                </div>
            </footer>
        </>
    )
}
