import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from "react-redux";
import { getSubStringId } from '../SubStringConvert';
import convertPSTtoLocalTime from '../convertPSTtoLocalTime';
import { useWebProperties } from '../webProperties';
import API from '../Network/API';
import moment from 'moment';
import Alert from '../Alert';
import Axios from 'axios';
import { Reports_URL } from '../config';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

// user document model and immigration admin model also
export default function MainTaskInfo(props) {

    const [names, setNames] = useState([])
    const [acknowledgeData, setAcknowledgeData] = useState([])
    const [userAck, setUserAck] = useState('')
    const [reason, setReason] = useState('')
    const [deneid, setDeneid] = useState(false)
    const [maybe, setMaybe] = useState(false)

    useEffect(() => {
        if (props.data.story_type === 'group') {
            API.post("getUpdateSprint.php", {
                story_us_id: props.data.us_id,
                action: "get_group_task_info",
            })
                .then(function (response) {
                    if (response.status === 'True') {
                        setNames(response.data.map(item => {
                            return item.fullname
                        }))
                    } else {
                        console.error("Response status is not 'True'.");
                    }
                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        const getUsersList = async () => {
            try {
                var response = await API.post("agile_squads.php", {
                    "crop": getUser.corp,
                    projectId: props.data.projectId,
                }, {}, false);
                if (response.status === 'True') {
                    const employeeData = response.data.filter(user => user.email !== "" && user.email !== null && user.name !== '')
                    const modifyData = props.data.acknowledgement.map(item => {
                        let reasonAck = null
                        if (item.reason) {
                            reasonAck = item.reason
                        }
                        const index = employeeData.findIndex(user => user.email === item.email)
                        const name = employeeData[index].name
                        return { ...item, name: name, reason: reasonAck }
                    })
                    const userIndex = modifyData.findIndex(user => user.email === getUser.user.userName)
                    const userResponse = modifyData[userIndex].ackStatus
                    setUserAck(userResponse)
                    setAcknowledgeData(modifyData)
                }
            } catch (error) {
                Alert('error', error.message);
            }
        }
        if (props.data.acknowledgement) {
            getUsersList()
        }
        // eslint-disable-next-line
    }, [])

    const getUser = useSelector(state => state.auth);
    const userRole = getUser?.user?.role;
    const classNames = useStyles();
    const { MAINTASKID, SUBTASK_DESCRIPTION, ASSIGNED_TO, ASSIGNED_BY, PROJECT_DESCRIPTION,
        ASSIGNED_DATE, TARGET_DATE, PROJECT_COMPLETED_DATE, SUBTASK_PROGRESS, SUBTASKTITLE,
        PROJECTNAME, MODULENAME, ACCEPTANCE_CRITERIA, MAINTASKNAME, STORY_POINTS, MAINTASK_DESCRIPTION, PRIORITY_LEVEL, MEETING_DATE, MEETING_TIME } = useWebProperties();

    const convertDatePattern = (date) => {
        if (date !== null) {
            return {
                date: moment(date).format('MM-DD-YYYY'),
                time: moment(date).format('HH:mm')
            }
        } else {
            return "NA"
        }
    }

    const submitResponse = async (response, reason) => {
        let userReason = null
        if (reason) {
            userReason = reason
        }
        const updateUser = acknowledgeData.map(user => {
            if (user.email === getUser.user.userName) {
                return { email: user.email, ackStatus: response, reason: userReason }
            } else {
                return { email: user.email, ackStatus: user.ackStatus, reason: user.reason }
            }
        })
        try {
            await Axios.post(`${Reports_URL}/calender/calAck`, {
                "acknowledge": JSON.stringify(updateUser),
                "event_id": props.data.calendarId
            }, {}, false);
            props.handleClose()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px', overflowWrap: "break-word" }}>
                                <div className="modal-body" style={{ maxHeight: '400px', overflow: 'auto' }}>
                                    {(props.data.view === "taskInfo") ?
                                        <div >
                                            {props.data.calendarId !== null && props.data.calendarId !== undefined && acknowledgeData?.length > 0 ? <div>
                                                <h2 style={{ fontSize: '20px' }}>Acknowledgement</h2>
                                                <div className='d-flex justify-content-between mb-2'>
                                                    {userAck === null ? <p><b>Give your availabilty response</b></p> : <p><b>You can change your availabilty response</b></p>}
                                                    {!deneid ? <div className='d-flex'>
                                                        <button className='btn btn-success mr-2' onClick={() => submitResponse('Accepted')}>Yes</button>
                                                        <button className='btn btn-warning mr-2' onClick={() => { setDeneid(true); setMaybe(true) }}>May be</button>
                                                        <button className='btn btn-danger' onClick={() => setDeneid(true)}>No</button>
                                                    </div> :
                                                        <div>
                                                            <div className='d-flex'>
                                                                <input type='text' style={{ width: '250px', marginRight: '2px', border: '1px solid black' }} placeholder='Please enter your reason' onChange={(e) => { setReason(e.target.value) }} />
                                                                <button className='btn btn-info mr-2' onClick={() => {
                                                                    if (!reason) {
                                                                        Alert('warning',"Please enter a reason");
                                                                    } else if (maybe) {
                                                                        submitResponse('May be', reason);
                                                                    } else {
                                                                        submitResponse('Denied', reason);
                                                                    }
                                                                }}>Submit</button>
                                                            </div>
                                                        </div>}
                                                </div>
                                                <div>
                                                    {acknowledgeData?.length > 0 ? <ul style={{ paddingLeft: '0px' }}>
                                                        {acknowledgeData.map(item => (
                                                            <li key={item.email} className='d-flex justify-content-between mb-2 border-bottom border-dark'>
                                                                <p style={{ fontSize: '12px' }}>{item.name}</p>
                                                                <div className='d-flex flex-column justify-content-end align-items-end'>
                                                                    {item.ackStatus === null ? <p className='bg-warning px-1 rounded-lg'>No response</p> :
                                                                        item.ackStatus === "Accepted" ? <p className=' bg-success px-1 text-white rounded-lg'>{item.ackStatus}</p> : item.ackStatus === "May be" ? <p className=' bg-warning px-1 text-white rounded-lg' style={{ width: "fit-content" }}>{item.ackStatus}</p> : <p className='bg-danger px-1 text-white rounded-lg fit-content' style={{ width: "fit-content" }}>{item.ackStatus}</p>}
                                                                    {item.reason !== null && <p style={{ fontSize: '12px' }}>{item.reason}</p>}
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul> : null}
                                                </div>
                                            </div> : null}
                                            <p><b>{MAINTASKID}</b> :&nbsp;{getSubStringId((props.data.project_name || getUser.user.corp), 3)}-{props.data.main_task_id !== null && props.data.main_task_id !== "0" ? `${props.data.main_task_id}-` : ''}
                                                {props.data.us_id}</p>

                                            {userRole !== 'Limited Access Contributor' ? (
                                                <p><b>{PROJECTNAME}</b> : {props.data.epicId === null ? 'NA' : props.data.projectName}</p>
                                            ) : null}
                                            {userRole !== 'Limited Access Contributor' ? (
                                                <p><b>{MODULENAME}</b> : {props.data.moduleId === null ? 'NA' : props.data.moduleName}</p>
                                            ) : null}

                                            <p><b>{MAINTASKNAME}</b> : {props.data.title} </p>
                                            {userRole !== 'Limited Access Contributor' ? (<p><b>{STORY_POINTS}</b> : {props.data.storyPoints}</p>) : null}
                                            <p><b>{ACCEPTANCE_CRITERIA}</b>: <span style={{ whiteSpace: 'break-spaces' }}>{props.data.acceptanceCriteria}</span> </p>
                                            <p><b>{PROJECT_DESCRIPTION}</b> : {props.data.description} </p>
                                            <p><b>{ASSIGNED_TO}</b> : {props.data.assignedTo}{names.length > 0 ? `, ${names.join(',')}` : ''}</p>
                                            <p><b>{ASSIGNED_BY}</b> : {props.data.assignedBy}</p>
                                            <p><b>{ASSIGNED_DATE}</b> : {convertPSTtoLocalTime(props.data.createdDate)} </p>
                                            {userRole !== 'Limited Access Contributor' ? (
                                                props.data.task_type !== 'sub_task' ?
                                                    <p><b>{SUBTASK_PROGRESS}</b> : {props.data.taskProgress}% </p>
                                                    :
                                                    null
                                            ) : null
                                            }
                                            <p><b>{TARGET_DATE}</b> : {convertDatePattern(props.data.targetDate).date}</p>
                                            {props.data.completedStatus === "completed" ? <p ><b>{PROJECT_COMPLETED_DATE}</b> : {convertPSTtoLocalTime(props.data.completedDate)} </p>

                                                : null}
                                            {props.data.calendarId !== null && props.data.calendarId !== undefined ? <p ><b>{MEETING_DATE}</b> : {convertDatePattern(props.data.startDate).date} </p>
                                                : null}
                                            {props.data.calendarId !== null && props.data.calendarId !== undefined ? <p ><b>{MEETING_TIME}</b> : {convertDatePattern(props.data.startDate).time} to {convertDatePattern(props.data.endDate).time} </p>
                                                : null}
                                        </div>
                                        : (props.data.view === "sprintInfo") ?
                                            <div>
                                                <p><b>Sprint Id</b> : {props.data.id} </p>
                                                {(props.data.epic != null) ?
                                                    <p><b>Epic Name</b> : {props.data.epic} </p> : null}
                                                <p><b>Sprint Name</b> : {props.data.title} </p>
                                                <p><b>Start Date </b>: {convertPSTtoLocalTime(props.data.startDate)} </p>
                                                <p><b>Target Date</b> : {convertPSTtoLocalTime(props.data.targetDate)} </p>
                                                <p><b>Created By</b> : {props.data.createdBy} </p>
                                                <p><b>Created On</b> : {convertPSTtoLocalTime(props.data.created_on)} </p>


                                            </div>
                                            : props.data.view === "private_todo_taskInfo" ?
                                                <div >
                                                    <p><b >{MAINTASKID}</b> :&nbsp;PT-{props.data.pt_id}</p>
                                                    <p><b>{MAINTASKNAME}</b> : {props.data.title} </p>
                                                    <p><b>{STORY_POINTS}</b> : {props.data.storyPoints} </p>
                                                    <p><b>{PRIORITY_LEVEL}</b> : {props.data.priority_level} </p>
                                                    <p><b>{ACCEPTANCE_CRITERIA}</b>: <span style={{ whiteSpace: 'break-spaces' }}>{props.data.acceptanceCriteria}</span> </p>
                                                    <p ><b>{MAINTASK_DESCRIPTION}</b> : {props.data.description} </p>
                                                    <p ><b>{ASSIGNED_BY}</b> : {props.data.assignedBy} </p>
                                                </div>
                                                :
                                                <div>
                                                    <p><b>{MAINTASKNAME}</b>:{getSubStringId((props.data.project_name || getUser.user.corp), 3)}-{props.data.us_id}-{props.data.taskTitle} </p>
                                                    <p><b>{SUBTASKTITLE}</b>:{getSubStringId((props.data.project_name || getUser.user.corp), 3)}-{props.data.us_id}-{props.data.t_id}-{props.data.task} </p>
                                                    <p><b>{SUBTASK_DESCRIPTION}</b>: {props.data.subTaskDesc}</p>
                                                    <p><b>{ACCEPTANCE_CRITERIA}</b>: <span style={{ whiteSpace: 'break-spaces' }}>{props.data.acceptanceCriteria}</span></p>
                                                    <p><b>{PRIORITY_LEVEL}</b> : {props.data.priorityLevel} </p>
                                                    <p><b>{ASSIGNED_TO}</b>: {props.data.assignedTo}</p>
                                                    <p><b>{ASSIGNED_BY}</b>: {props.data.assignedBy}</p>
                                                    <p><b>{TARGET_DATE}</b>: {props.data.targetDate}</p>

                                                </div>
                                    }
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-success" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}