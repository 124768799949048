import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";
import * as actions from '../actions';

//get Existed Employees list for adding the user into project
export async function getSquadList(dispatch, getUser) {
    const [username, extension] = (getUser.userName).split('@')
    dispatch(isLoading());
    try {
      var response = await API.post("get_user.php", {
        before_extension: username,
        extension: extension,
        action : "get_squads_list",
        account_id: getUser.account_id
      }, {}, false);
      if (response.status === 'True') {
        dispatch(actions.squadList(response.data))
      }
      else {
        dispatch(actions.squadList([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }