import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
import { addAllSubTask, addSubTask } from './Services/addSubTask';
import { subtasksReducer, initialState } from './subtaskReducer';
import { useWebProperties } from '../webProperties';
import Select, { components } from 'react-select';
import { getUsers } from './Services/getProjectUsers';
import { addSubTaskToWorkflow } from './Services/addSubTaskToWorkflow';
import { createWorkflowTemplate } from './Services/createWorkflowTemplate';
import { getSubStringId } from '../SubStringConvert';
import Alert from '../Alert';
import getAiTaskDetails from '../TasksModals/Services/getAiTaskDetails';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New SubTask
export default function AddSubTask(props) {

    const [state, dispatch] = useReducer(subtasksReducer, initialState);
    const getUser = useSelector(state => state.auth)
    const classNames = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const { SUBTASK, SUBTASK_DESCRIPTION, TARGET_DATE, SUBTASK_ADD, MAINTASK, MODULE, SELECT_SQUAD_MEMBER, ACCEPTANCE_CRITERIA, DEFINITION_OF_DONE, AI_ENABLE, AI_MODEL } = useWebProperties();
    useEffect(() => {
        getUsers(dispatch, getUser.user, props.data.project_id);
        // eslint-disable-next-line
    }, [])
    const activities = useSelector(state => state.landingReducer.userActivities)
    var userDetails = [];
    state.users.map((users, index) => {
        return (
            userDetails.push({ 'value': users.id, 'label': users.name, customId: `user-id-${index}` })
        );
    })
    const CustomOption = (props) => {
        return (
            <components.Option {...props} innerProps={{ ...props.innerProps, id: props.data.customId }}>
                {props.data.label}
            </components.Option>
        );
    };

    const handleAllAdd = async () => {
        try {
            await Promise.all(
                props.data.allData.map(item =>
                    addAllSubTask(
                        activities,
                        state,
                        dispatch,
                        getUser.user,
                        item.story_id,
                        props.data.moduleId,
                        item.assigned_to,
                        props.data.targetDate,
                        props.handleClose,
                        SUBTASK_ADD,
                        MODULE,
                        MAINTASK,
                        SUBTASK,
                        props.data.project_id
                    )
                )
            );

            Alert("success", SUBTASK_ADD);
        } catch (error) {
            console.error("Error adding subtasks:", error);
            Alert("error", "Failed to add subtasks");
        }
    };


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    {
                                        props.data.action === "addAllSubtask" ?
                                            <h5 class="modal-title p-2">{props.data.title || props.data.data.story_title}</h5>
                                            :
                                            <h5 class="modal-title p-2">{getSubStringId(props.data.projectName || getUser.user.corp, 2)}   {props.data.us_id || props.data.data.us_id}{'-'}{props.data.title || props.data.data.story_title}</h5>
                                    }
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body overflow-auto" style={{ height: 500 }}>
                                {
                                        AI_ENABLE === '1' ?
                                            <div class="input-group mb-3" style={{ position: "relative" }}>
                                                <input type="text" class="form-control" style={{ borderRadius: '25px', padding: '8px', paddingRight: "45px" }} placeholder="Type to generate using AI" aria-label="Recipient's username" aria-describedby="button-addon2"
                                                    value={state.aitaskinput.value}
                                                    onChange={(event) => dispatch(actions.aitaskinput(event.target.value))}
                                                />
                                                {/* <span style={{ color: "red", fontSize: '12px' }}>{state.aitaskinput.errorStatus ? (state.aitaskinput.errormessage   ).toLowerCase() : ""}</span> */}
                                                <button type="button" class="btn btn-outline-secondary btn-sm"
                                                    style={{ borderRadius: '10000px', position: "absolute", right: "5px", padding: "3px", marginTop: '6px', marginRight: '3px', zIndex: '500' }}
                                                    onClick={() => getAiTaskDetails(state.aitaskinput.value, dispatch, setIsLoading, getUser, activities["AI to generate task details"],AI_MODEL)}
                                                    disabled={state.aitaskinput.value.length === 0 || isLoading}
                                                >
                                                    {!isLoading ? <ArrowUpwardRoundedIcon fontSize={"medium"} backgroundColor={"grey"} /> :
                                                        (<div class="spinner-border text-primary" role="status">
                                                            <span class="visually-hidden"></span>
                                                        </div>)
                                                    }

                                                </button>

                                            </div>
                                            :
                                            null
                                    }
                                    <div class="form-group" style={{ height: 'auto' }}>
                                        <label for="recipient-name" class="col-form-label pt-0" style={{ width: '150px' }}>{SUBTASK} Title<span style={{ color: "red" }} >*</span></label>
                                        <input type="text" class="form-control" id="title" name="title" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                            value={state.taskTitle.value}
                                            onChange={(event) => dispatch(actions.taskTitle(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskTitle.errorStatus ? state.taskTitle.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{SUBTASK_DESCRIPTION}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="description" name="description" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '65px' }}
                                            value={state.taskDescription.value}
                                            onChange={(event) => dispatch(actions.taskDescription(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskDescription.errorStatus ? state.taskDescription.errormessage : ""}</span>
                                    </div>
                                    {
                                        props.data?.action !== "addAllSubtask" ?
                                            <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                                <label for="user" className="col-form-label pt-2" style={{ width: '150px' }}>{SELECT_SQUAD_MEMBER}</label>
                                                <Select
                                                    className="form-control"
                                                    placeholder={` ${SELECT_SQUAD_MEMBER}`}
                                                    id='users'
                                                    maxMenuHeight={130}
                                                    value={userDetails.value}
                                                    onChange={(selectedOption) => {
                                                        dispatch(actions.userSelected(selectedOption.value))
                                                    }}
                                                    options={userDetails}
                                                    components={{ Option: CustomOption }}
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{ACCEPTANCE_CRITERIA}/{DEFINITION_OF_DONE}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="criteria" name="criteria" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '65px' }}
                                            value={state.acceptanceCriteria.value}
                                            onChange={(event) => dispatch(actions.acceptanceCriteria(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.acceptanceCriteria.errorStatus ? state.acceptanceCriteria.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="target_date" class="col-form-label pt-2" style={{ width: '250px' }}>{TARGET_DATE}<span style={{ color: "red" }} >*</span></label>
                                        <input type="date" class="form-control" id="target_date" name="target_date" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                            value={state.targetDate.value}
                                            onChange={(event) => dispatch(actions.targetDate(event.target.value))} />
                                    </div>
                                </div>
                                {(props.data.view !== "addWorkflowTemplate") ?
                                    <div class="modal-footer">
                                        {
                                            props.data?.action === "addAllSubtask" ?

                                                <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => {
                                                    handleAllAdd()
                                                }}>Add</button>
                                                :
                                                <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { addSubTask(activities, state, dispatch, getUser.user, props.data.mainTaskId, props.data.moduleId, props.data.assignedTo, props.data.targetDate, props.handleClose, SUBTASK_ADD, MODULE, MAINTASK, SUBTASK, props.data.project_id) }}>Add</button>

                                        }
                                        {props.data.is_workflow === "1" && (
                                            <>
                                                <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { addSubTaskToWorkflow(state, dispatch, getUser.user, props.data.mainTaskId, props.data.moduleId, props.data.assignedTo, props.handleClose, SUBTASK_ADD, SUBTASK, props.data.template_story_id, props.data.project_id) }}>Add & Save as Workflow</button>
                                                {/* <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { createWorkflowTemplate(state, dispatch, getUser.user, props.data.mainTaskId, props.data.moduleId, props.data.assignedTo, props.handleClose, SUBTASK_ADD, SUBTASK); } }>Add Workflow Template</button> */}
                                            </>
                                        )}
                                    </div>
                                    : props.data.data.is_workflow === "1" && (
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { createWorkflowTemplate(state, dispatch, getUser.user, props.data.data.story_id, props.data.data.moduleId, props.data.data.assignedTo, props.handleClose, SUBTASK_ADD, SUBTASK, props.data.project_id) }}>Save as Workflow Template</button>
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}
