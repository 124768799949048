import React, { useEffect, useState } from 'react';
// import Header from '../TopNav';
import SideNavigation from '../SideNav';
import { getToken, setToken } from '../LocalStorage';
import TopNavWithOutProject from '../../UserModule/Utility/TopNav/topnav';
import API from '../Network/API';
import { useWebProperties } from '../webProperties';
import { getSubStringId } from '../SubStringConvert';
import { useSelector } from 'react-redux';
import MainTaskChatBox from '../ChatMainTask';
import MainTaskInfo from '../TasksModals/mainTaskInfo';
import MainTaskVerify from '../TaskVerify/mainTaskVerify';
import ModifyMainTask from '../TasksModals/modifyMainTask';
import { isMobile } from 'react-device-detect';
import TaskInfo from "../../UserModule/UserDashboard/taskInfo";
import AddSubTask from '../SubTaskModals/addSubTask';
import { Link } from 'react-router-dom'


export default function ViewGroupTask(props) {

    const [tasksData, setTasksData] = useState([])
    const getUser = useSelector(state => state.auth)
    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState();

    const [allMessages, setAllMessages] = useState([])

    var data = {
        us_id: props?.location?.state?.us_id || getToken('us_id'),
        title: props?.location?.state?.title || getToken('title'),

    }

    setToken('us_id', data.us_id)
    setToken('title', data.title)

    const { MAINTASK, CHAT, COMMENTS, VERIFY, SCRUM_MASTER, ACTION_ICON, PRODUCT_OWNER, role_array, REASSIGN, VIEW_DETAILS, NEWSUBTASK, WORKFLOW_ENABLE, VIEWSUBTASKS } = useWebProperties();


    useEffect(() => {
        getSubGroupTasks()
        getAllTaskMessages()
        // eslint-disable-next-line
    }, [])


    function getSubGroupTasks() {
        API.post("getUpdateSprint.php", {
            story_us_id: data.us_id,
            action: "get_group_task_info",
        })
            .then(function (response) {
                if (response.status === 'True') {
                    setTasksData(response.data.filter(item => {
                        return item.main_task_id !== '0'
                    }));

                } else {
                    console.error("Response status is not 'True'.");
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    async function getAllTaskMessages() {
        try {
            var response = await API.post("user_story_chat.php", {
                corp_code: getUser.user.corp,
                action: "getAllTaskMessages",
                "projectId": getUser.user.projectId
            }, {}, false);
            if (response.status === 'True') {
                setAllMessages(response.data);
            } else {
                setAllMessages([])
            }
        } catch (error) {
            console.log('error', error.message)
        }
    }

    const getMessagesCount = (id, empId, data) => {
        const msgCount = allMessages.filter(message => message.readBy.split(",").indexOf(empId) === -1 && message.messagedBy !== empId && message.groupId === id).map((messages, i) => {
            // eslint-disable-next-line
            return i, messages
        })
        return (
            <i>
                {/* {msgCount.length > 0 ? msgCount.length : null} */}
                {
                    msgCount.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handleOpen("maintask", data, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold", marginTop: '-5px' }}>{msgCount.length}</span>
                        </div>
                        // <img src="images/common/chat.svg" alt="logo" style={{ width: '20px', height: '20px', backgroundColor: 'green' }} onClick={(event) => handleOpen("maintask", id, msgCount)} />
                        :
                        <div style={{ display: 'flex', justifyContent: 'start' }}>
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handleOpen("maintask", data, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    const handleOpen = (action, data, sno) => {

        var info

        if (action === 'maintask') {
            info = {
                us_id: data.us_id, action: action, id: data.us_id, sno: sno, main_task_id: data.main_task_id,
                story_type: data.story_type,
                group_task_type: data.group_task_type
            }
        }
        else if (action === "commentInfo") {
            info = {
                us_id: data.us_id,
                title: data.story_title,
                id: data.story_id,
            }
        }
        else if (action === "taskInfo" || action === "commentInfo") {
            var view_status = "taskInfo"
            info = {
                view: view_status,
                us_id: data.us_id,
                projectName: data.epic_name, //epic/module name
                epicId: data.epic_id,
                moduleName: data.sprint_desc,
                moduleId: data.sprint_id,
                title: data.story_title,
                description: data.story_desc,
                taskId: data.story_id,
                createdDate: data.created_date,
                taskProgress: data.story_status,
                storyPoints: data.story_points,
                acceptanceCriteria: data.acceptance_criteria,
                assignedTo: data.assignedto,
                assignedBy: data.assignedby,
                completedDate: data.modified_date,
                completedStatus: data.complete_status,
                targetDate: data.target_date,
                device_id: data.device_id,
                project_name: data.project_name,
                main_task_id: data.main_task_id,
                story_type: data.story_type,
            }
        }

        // verify
        else if (action === "Verify") {
            info = {
                us_id: data.us_id,

                mainTaskId: data.story_id,
                subTaskId: data.us_id,
                title: data.story_title,
                description: data.story_desc,
                action: action,
                moduleId: data.sprint_id
            }
        }

        // reassign
        else if (action === "reassign") {
            info = {
                projectName: data.story_title,
                moduleId: data.sprint_id,
                ideaId: data.ideaId,
                title: data.story_title,
                description: data.story_desc,
                taskId: data.story_id,
                acceptanceCriteria: data.acceptance_criteria,
                storyPoints: data.story_points,
                userDetails: data.fullname,
                id: data.assigned_to,
                priorityLevel: data.priority_level,
                action: action,
                assignedTo: data.assigned_to,
                assignedBy: data.assignedby,
                status: data.complete_status,
            };

        } else if (action === "addSubtask") {
            info = {
                us_id: data.us_id,
                mainTaskId: data.story_id,
                action: action,
                moduleId: data.sprint_id,
                title: data.story_title,
                ideaId: data.epic_id,
                assignedTo: data.assigned_to,
                is_workflow: data.is_workflow,
                targetDate: data.target_date,
                template_story_id: data.template_story_id,
            }
        } else if (action === "addAllSubtask") {
            info = {
                us_id: data.map(item => {
                    return item.us_id
                }),
                mainTaskId: data.map(item => {
                    return item.story_id
                }),
                action: action,
                moduleId: data[0].sprint_id,
                title: data[0].story_title,
                ideaId: data[0].epic_id,
                assignedTo: data.map(item => {
                    return item.assigned_to
                }),
                is_workflow: data[0].is_workflow,
                targetDate: data[0].target_date,
                template_story_id: data[0].template_story_id,
                allData: data

            }
        }

        setCardInfo(info);
        setOpen({ status: true, action: action })

    }

    const handleClose = () => {
        setOpen({ status: false, index: 0 });
        getSubGroupTasks();
        getAllTaskMessages()
    };

    const handleModalClose = () => {
        setOpen({ status: false, index: 0 })
        getAllTaskMessages()
    };
    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideNavigation />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="row" style={{ padding: '16px', justifyContent: 'space-between' }}>
                                            <div className="row">
                                                <h4 className="card-title">GROUP {MAINTASK}</h4>
                                                <h4 className="card-title text-success">: {`${getSubStringId(tasksData[0]?.project_name, 3)}-${data.us_id}-${data.title}`}</h4>
                                            </div>
                                            <div>
                                                {
                                                    WORKFLOW_ENABLE === '1' && tasksData[0]?.assigned_by === getUser.user.empId ?
                                                        <button className="btn btn-info badge text-center" onClick={(event) => handleOpen("addAllSubtask", tasksData)}>{NEWSUBTASK}s</button>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {tasksData.map((item, index) => (
                                        <div className="card col-12" key={index}>
                                            <div className="d-flex col-12" style={{ borderWidth: 2, borderColor: 'red' }}>
                                                <div className="d-flex align-items-center" style={{ flex: 1, justifyContent: 'space-around' }}>
                                                    <b style={{ cursor: 'pointer' }} onClick={(event) => handleOpen("taskInfo", item)}>
                                                        {item.complete_status === 'pending' ? (
                                                            <p style={{ color: 'black' }}>
                                                                {getSubStringId(item.project_name, 3)}-
                                                                {item.main_task_id !== null && item.main_task_id !== "0" ? `${item.main_task_id}-` : ''}
                                                                {item.us_id}-{item.story_title}
                                                            </p>
                                                        ) : (
                                                            <del>
                                                                <p>
                                                                    {getSubStringId(item.project_name, 3)}-
                                                                    {item.main_task_id !== null && item.main_task_id !== "0" ? `${item.main_task_id}-` : ''}
                                                                    {item.us_id}-{item.story_title}
                                                                </p>
                                                            </del>
                                                        )}
                                                    </b>
                                                    {/* status */}
                                                    <div>{item.complete_status === 'verified' ? <p style={{ backgroundColor: 'green', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: isMobile ? '' : '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom" title={'Verified by ' + item.modifiedby}>Verified</p> : null}</div>
                                                    {item.story_type === "group" &&
                                                        <p style={{ backgroundColor: 'rgb(40, 210, 252)', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">GR {item.group_task_type !== null ? `${item.group_task_type?.substring(0, 1)}A` : null}</p>
                                                    }
                                                    {item.task_count > 0 ?
                                                        <p style={{ backgroundColor: '#8F00FF', cursor: 'pointer', color: 'white', padding: '3px', marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">WT</p>
                                                        :
                                                        <p style={{ color: 'white', padding: '3px', marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom"></p>

                                                    }

                                                    <div style={{ backgroundColor: '#81B622', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, borderRadius: '90px', fontSize: '15px', width: '40px', textAlign: 'center', }} data-toggle="tooltip" data-placement="bottom" title={item.assignedto}>{item.assignedto === 'NA' ? 'NA' : getSubStringId(item.assignedto, 1)}</div>

                                                    {/* story_status_desc */}

                                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '10px', padding: "0" }}>
                                                        {getMessagesCount(item.us_id, getUser.user.empId, item)}
                                                    </button>
                                                    {/* activity log */}
                                                    <button style={{
                                                        border: "0",
                                                        width: "8px",
                                                        height: "30px",
                                                        backgroundColor: "transparent",
                                                        marginLeft: "20px",
                                                        display: isMobile ? "none" : {},
                                                    }}
                                                        type="button"
                                                        onClick={() =>
                                                            handleOpen(
                                                                "commentInfo", item
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src="images/common/comments.svg"
                                                            title={COMMENTS}
                                                            alt="logo"
                                                            style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                                                        />
                                                    </button>
                                                    <div className="dropdown show" style={{ cursor: 'pointer', marginLeft: 5, marginRight: 5, padding: '5px' }}>
                                                        <button
                                                            id="dropdownMenuLink"
                                                            data-toggle="dropdown"
                                                            aria-expanded="false"
                                                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                                                        >
                                                            <img
                                                                src="images/common/actionmenu.png"
                                                                title={ACTION_ICON}
                                                                alt="logo"
                                                                style={{ width: '15px', height: '15px', borderRadius: '0', display: isMobile ? "none" : {} }}
                                                            />
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                            <div>{(accessRole && item.complete_status === 'completed') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify", item)}>{VERIFY}</button> : null}
                                                            </div>

                                                            <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#203B5A", color: 'white' }} onClick={(event) => handleOpen("taskInfo", item)}>{VIEW_DETAILS}</button>

                                                            {/* Reassign */}
                                                            <div>

                                                                {(accessRole && item.complete_status === 'completed') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "orange", color: 'white' }} onClick={(event) => handleOpen("reassign", item)}>{REASSIGN}</button> : null}
                                                            </div>
                                                            {(item.complete_Status === 'pending' && tasksData.complete_status === 'verified' && accessRole) ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#6BC2D3", color: 'white' }} onClick={(event) => handleOpen("Verify", item)}>{VERIFY}</button> : null}
                                                            {
                                                                WORKFLOW_ENABLE === '1' && item.assigned_by === getUser.user.empId ?
                                                                    <>
                                                                        <button className="dropdown-item badge badge-pill badge-secondary text-center" style={{ backgroundColor: "#630436", color: 'white' }} onClick={(event) => handleOpen("addSubtask", item)}>{NEWSUBTASK}</button>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                WORKFLOW_ENABLE === '1' ?
                                                                    item.task_count > 0 ?
                                                                        <button className="dropdown-item badge badge-pill badge-primary text-center" style={{ backgroundColor: "#9a7b78", color: 'white' }}><Link to={{ pathname: '/viewSubTasks', state: { id: item.story_id, title: item.story_title, moduleId: item.sprint_id, ideaId: item.idea_id, subTasksAssignedTo: item.assigned_to, us_id: item.id, template_story_id: item.template_story_id, is_workflow: item.is_workflow, completeStatus: item.completeStatus, story_type: item.story_type, project_id: item.project_id } }} style={{ color: 'white' }}>{VIEWSUBTASKS}</Link></button> : null
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                        {
                            open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === 'maintask' ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === "Verify" ? <MainTaskVerify open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === "reassign" ? <ModifyMainTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === "addSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }
                        {
                            open.action === "addAllSubtask" ? <AddSubTask open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                            /> : null
                        }


                        {open.action === "commentInfo" ? (
                            <TaskInfo
                                open={open.status}
                                handleClose={handleClose}
                                data={cardInfo}
                                handleModalClose={handleModalClose}
                            />
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
    )
}