import Alert from "../../../Common/Alert";
import { logEntry } from "../../../Common/logEntry";
import API from "../../../Common/Network/API";
import { addCommentUserStory, addTasksComment } from "./addCommentUserStory";

export async function pauseUserStory(activities = null, getUser, todoStoryId, inProgressStoryId, assignedTo, handleClose, passedMessage, MAINTASK, task_type, inprogress_task_type, SUBTASK) {

    console.log(activities = null, getUser, todoStoryId, inProgressStoryId, assignedTo, handleClose, passedMessage, MAINTASK, task_type, inprogress_task_type, SUBTASK)

    const message = `${MAINTASK}, ${passedMessage}, in progress`;
    const SubMessage = `${SUBTASK}, ${passedMessage}, in Progress`;
    const SubMessage1 = `${SUBTASK}, ${passedMessage}, moved into Pause/Hold`;
    const message1 = `${MAINTASK}, ${passedMessage}, moved into To Do Pause/Hold`;


    try {
        const start_time = Date.now()
        var response = await API.post(
            "manage_userstories.php",
            {
                crop: getUser.corp,
                empId: getUser.empId,
                todoStoryId: todoStoryId,
                inprogressStoryId: inProgressStoryId,
                action: task_type === "main_task" ? "change_userstory_status" : "change_subtask_status",
                assignedTo: assignedTo,
                inprogress_task_type: inprogress_task_type,
                isPaused: "paused"
            },
            {},
            false
        );
        const end_time = Date.now()
        if (response.status === "true") {
            // activities && logEntry({user_id:getUser.userName,logging_level:3,activity_id:activities["Task Management"],
            //     sub_activity:"In Progress",
            //     response_time : (end_time-start_time), task_meta_data:{story_id:inProgressStoryId}
            //   })

            activities && logEntry({
                user_id: getUser.userName, logging_level: 3, activity_id: activities["Task Management"],
                sub_activity: "To Do",
                response_time: (end_time - start_time), task_meta_data: { story_id: todoStoryId }
            },getUser)
            if (todoStoryId !== "") {
                if (inprogress_task_type === "main_task") {
                    if (task_type === "main_task") {
                        addCommentUserStory(getUser, todoStoryId, message, "1");
                    } else {
                        addTasksComment(getUser, todoStoryId, "1", SubMessage);
                    }
                } else {
                    if (task_type === "main_task") {
                        addCommentUserStory(getUser, todoStoryId, message, "1");
                    } else {
                        addTasksComment(getUser, todoStoryId, "1", SubMessage);
                    }
                }
                activities && logEntry({
                    user_id: getUser.userName, logging_level: 3,
                    activity_id: activities["Task Management"], sub_activity: "In Progress",
                    response_time: (end_time - start_time), task_meta_data: { story_id: inProgressStoryId }
                },getUser)
            }
            if (inprogress_task_type === "main_task") {
                addCommentUserStory(getUser, inProgressStoryId, message1, "10");

            } else {
                addTasksComment(getUser, inProgressStoryId, "10", SubMessage1);

            }
            handleClose();
            // activities && logEntry({user_id:getUser.userName,logging_level:3,
            //   activity_id:activities["Task Management"],sub_activity:"To Do",
            //   response_time : (end_time-start_time), task_meta_data:{story_id:inProgressStoryId}
            // })
        } else {
            Alert("warning", response.message);
        }
    } catch (error) {
        Alert("warning", error);
        activities && logEntry({
            user_id: getUser.userName, logging_level: 2,
            activity_id: activities["Task Management"], sub_activity: "To Do",
            error_message: error.message, task_meta_data: { story_id: inProgressStoryId }
        },getUser)
    }
    handleClose()
}