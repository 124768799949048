import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { tasksReducer, initialState } from './tasksReducer';
import { useSelector } from 'react-redux';
import { updateUserStory } from './Services/updateUserStory';
import { getActiveSubUserStory, getActiveUserStory } from './Services/getActiveUserStory';
import { updateSetDone } from './Services/updateSetDone';
import { useWebProperties } from '../../Common/webProperties';
import ChangeUserstoryStatus from '../Modules/changeUserstoryStatus';
import { getSubStringId } from '../../Common/SubStringConvert';
import Axios from 'axios';
import { Reports_URL } from '../../Common/config';
import RootLoader from '../../Common/Loader/RootLoader';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));
//For Add New Project
export default function ChangeStatus(props) {

    const getUser = useSelector(state => state.auth)
    const { CHANGE_STATUS, MAINTASK, TASKS_WARNING, ROADBLOCK_WARNING, SUBTASK, AI_MODEL } = useWebProperties();
    const [open, setOpen] = useState({ status: false, index: 0 })
    // eslint-disable-next-line
    const [state, dispatch] = useReducer(tasksReducer, initialState);
    const classNames = useStyles();
    const [cardInfo, setCardInfo] = useState()
    const [errorMessage, setErrorMessage] = useState(null)
    const [taskDescription, setTaskDescription] = useState(null)
    const [loader, setLoader] = useState(false)
    const activities = useSelector(state => state.landingReducer.userActivities)
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getCompleteResons()
        // eslint-disable-next-line
    }, [])

    const handleOpen = (action, index, data) => {
        var info
        setOpen({ status: true, action: action });
        if (action === "changeUserstoryStatus") {
            info = {
                action: action,
                inprogress: data.inprogressStoryId,
                todo: data.todoStoryId,
                currentSprint: data.currentSprint,
                assignedTo: data.assignedTo,
                projectName: data.projectName,
                story_title: data.story_title,
                inprogress_story_title: data.inprogress_story_title,
                us_id: data.us_id,
                todo_us_id: props.data.t_id !== null ? `${props.data.us_id}-${props.data.t_id}` : data.todo_us_id,
                task_type: data.task_type,
                inprogress_task_type: data.inprogress_task_type,
                toDoProjectName: data.toDoProjectName
            }
        }
        setCardInfo(info);
    }

    const completeSubTask = () => {
        setLoader(true)
        if (taskDescription === null) {
            setErrorMessage(`Please enter complete reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            let message = `${taskDescription}, ${SUBTASK} is completed`;
            updateSetDone(activities, dispatch, getUser.user, props.data.subTaskId, props.data.taskId, props.handleClose, message)
        }
    }

    const completeMainTask = () => {
        setLoader(true)
        if (taskDescription === null) {
            setErrorMessage(`Please enter complete reason`)
            setLoader(false)
        } else {
            setErrorMessage(null)
            let message = `${taskDescription}, ${MAINTASK} is completed`
            updateUserStory(dispatch, getUser.user, props.data.taskId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, props.data.story_type, props.data.us_id, props.data.main_task_id, activities)
        }
    }

    const getCompleteResons=async()=>{
        setLoader(true)
        try {
          var response = await Axios.post(`${Reports_URL}/ai/getTaskCompletionQuestions`, {
            "acceptance_criteria": props.data.acceptanceCriteria,
            "role":"Developer",
            "model": AI_MODEL
          }, {}, false);
          const questionData=response.data.questions.map((q,index) => {
            return {id:index,question:q.question,answer:q.default_answer}
          })
          setQuestions(questionData)
          if (Array.isArray(questionData)){
            const result=questionData.reduce((acc, q) => {
                acc[q.id] = q.answer ? q.answer : '';
                return acc;
              }, {})
            setAnswers(result)
          }
          setLoader(false)
        } catch (error) {
          setLoader(false)
          console.log(error)
        }
      }

    const handleInputChange = (id, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [id]: value,
        }));
    };

    const validateAnswers = () => {
        const newErrors = {};
        questions.forEach((q) => {
            if (!answers[q.id]) {
            newErrors[q.id] = `* Required field`;
            }
        });
        return newErrors;
    };

    const completeTaskWithQuestions = async () => {
        const validationErrors = validateAnswers();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }else{
            let description=''
            questions.forEach((q) => {
                description+=`${answers[q.id]},`
            })
            if (props.data.action === 'subtask_changeStatus') {
                let message = `${description}, ${SUBTASK} is completed`;
                updateSetDone(activities, dispatch, getUser.user, props.data.subTaskId, props.data.taskId, props.handleClose, message)
            } else {
                let message = `${description}, ${MAINTASK} is completed`
                updateUserStory(dispatch, getUser.user, props.data.taskId, props.handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, props.data.story_type, props.data.us_id, props.data.main_task_id, activities)
            }
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center" >
                            <div className="modal-content col-5 p-2 grp gr-1" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{CHANGE_STATUS}</h5>
                                </div>
                                <div className="modal-body">
                                    {(props.data.completeStatus === 'pending' && props.data.activeStatus === '0') ?
                                        <b>{getSubStringId(getUser.user.corp, 2)}-{props.data.us_id}{'-'}{props.data.t_id} {props.data.title} will be moved to In Progress</b> : <b>{(getUser.user.corp).substring(0, 3).toUpperCase()}-{props.data.us_id}{'-'}{props.data.title} will be moved to Done</b>
                                    }
                                    {props.data.action === 'subtask_changeStatus' ?
                                        <div>
                                            {(props.data.completeStatus === 'pending' && (props.data.activeStatus === '0' || props.data.activeStatus === '-1')) ?
                                                null
                                                :
                                                <>
                                                    {loader?<RootLoader />:
                                                    <>
                                                    {questions.length>0?
                                                        <div>
                                                            {questions?.map((q) => (
                                                                <div key={q.id} className="question">
                                                                <label>{q.id+1}.{q.question}</label>
                                                                <textarea
                                                                    className='form-control'
                                                                    value={answers[q.id] || ''}
                                                                    onChange={(e) => handleInputChange(q.id, e.target.value)}
                                                                />
                                                                {/* Display error message if exists */}
                                                                {errors[q.id] && <p style={{ color: 'red' }}>{errors[q.id]}</p>}
                                                                </div>
                                                            ))}
                                                        </div>:
                                                    <>
                                                        <label>Complete Reason</label>
                                                        <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                                        {errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}

                                                    </>}
                                                    </>}
                                                </>
                                            }</div>
                                        :
                                        <div>
                                            {(props.data.completeStatus === 'pending' && props.data.activeStatus === '0') ?
                                                null
                                                :
                                                <>
                                                    {loader?<RootLoader />:
                                                    <>
                                                        {questions.length>0?
                                                            <div>
                                                                {questions?.map((q) => (
                                                                    <div key={q.id} className="question">
                                                                    <label>{q.id+1}.{q.question}</label>
                                                                    <textarea
                                                                        className='form-control'
                                                                        value={answers[q.id] || ''}
                                                                        onChange={(e) => handleInputChange(q.id, e.target.value)}
                                                                    />
                                                                    {/* Display error message if exists */}
                                                                    {errors[q.id] && <p style={{ color: 'red' }}>{errors[q.id]}</p>}
                                                                    </div>
                                                                ))}
                                                            </div>:
                                                        <>
                                                            <label>Complete Reason</label>
                                                            <textarea className='form-control' onChange={(event) => setTaskDescription(event.target.value)} />
                                                            {errorMessage !== null && <label style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</label>}
                                                        </>}
                                                    </>}
                                                </>
                                            }
                                        </div>}

                                </div>
                                <div class="modal-footer justify-space-between cl-1" >
                                    <button type="button" class="btn btn-outline-danger" onClick={props.handleModalClose} style={{ borderRadius: '20px' }}>Cancel</button>
                                    {props.data.action === 'subtask_changeStatus' ?
                                        <div>
                                            {(props.data.completeStatus === 'pending' && (props.data.activeStatus === '0' || props.data.activeStatus === '-1')) ?
                                                <button disabled={state.isLoading} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { getActiveSubUserStory(activities, dispatch, getUser.user, props.data.subTaskId, props.data.taskId, props.handleClose, handleOpen, `${SUBTASK} in progress`) }}  >Confirm</button>
                                                : <button disabled={state.isLoading || loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { questions.length>0?completeTaskWithQuestions():completeSubTask() }}  >Confirm</button>
                                            }</div>
                                        :
                                        <div>
                                            {(props.data.completeStatus === 'pending' && props.data.activeStatus === '0') ?
                                                <button disabled={state.isLoading} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { getActiveUserStory(dispatch, getUser.user, props.data.taskId, props.data.sprintId, props.handleClose, handleOpen, `${MAINTASK} in progress`, activities) }}  >Confirm</button>
                                                : <button disabled={state.isLoading || loader} type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { questions.length>0?completeTaskWithQuestions():completeMainTask() }}  >Confirm</button>}
                                        </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            {
                open.action === "changeUserstoryStatus" && cardInfo != null ? (
                    <ChangeUserstoryStatus open={open.status} handleClose={props.handleClose} data={cardInfo} handleModalClose={props.handleModalClose} />)
                    : null}
        </div >
    );
}
