import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../Network/API';
import RootLoader from '../Loader/RootLoader';
import Axios from 'axios';
import { Reports_URL } from '../config';

const EventEmailRespond = () => {
  const query = new URLSearchParams(useLocation().search);
  const reply = query.get('response');
  const email = query.get('email');
  const eventId = query.get('eventId');
  //const type = query.get('type');
  const corp_code = query.get('corp_code')
  console.log(email, "email sending")
  const [response, setResponse] = useState(false);
  const [loader, setLoader] = useState(false);
  const [massage, setMassage] = useState(false) 
  
  const submitResponse = async (reply,event,userDetails) => {
    let response = null
    if (reply === 'yes') {
        response = 'Accepted'
    } else if (reply === 'no') {
        response = 'Denied'
    }
    const acknowledgeData = JSON.parse(event.acknowledge)
    const modifyData = acknowledgeData.map(item => {
      let reasonAck = null
      if (item.reason) {
          reasonAck = item.reason
      }
      return { ...item,reason: reasonAck }
  })
    const updateUser = modifyData.map(user => {
        if (user.email === userDetails.email) {
            return { email: user.email, ackStatus: response, reason: user.reason }
        } else {
            return { email: user.email, ackStatus: user.ackStatus, reason: user.reason }
        }
    })
    try {
        await Axios.post(`${Reports_URL}/calender/calAck`, {
            "acknowledge": JSON.stringify(updateUser),
            "event_id": event.id
        }, {}, false);
    } catch (error) {
        console.log(error)
    }
}

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Step 1: Fetch user info
        const userResponse = await API.post('get_user.php', {
          action: 'get_user_with_email',
          email: email,
        }, {}, false);

        if (userResponse.status === 'True') {
          const employeId = userResponse.data[0].empid;

          // Step 2: Fetch task details
          const taskResponse = await API.post('calendarDash.php', {
            action: 'getEventInfoWithId',
            event_id: eventId,
          }, {}, false);

          if (taskResponse.status === 'True') {
            const storyId = taskResponse.task_details[0].us_id;
            await submitResponse(reply,taskResponse.task_details[0],userResponse.data[0]);

            // Step 3: Send chat message
            let message;
            if (reply === 'yes') {
              message = 'Hello, thank you for the invite. I confirm my attendance and look forward to our meeting.';
            } else if (reply === 'no') {
              message = 'Hello, thank you for the invitation. Unfortunately, I won’t be able to attend. Please feel free to share any important notes or updates.';
            }

            const messageReply = await API.post("user_story_chat.php", {
              action: "send",
              corp_code: corp_code,
              groupId: storyId,
              message: message,
              messagedBy: employeId,
              msgId: " ",
              groupName: '',
              groupEmail: '',
              reply_id: "",
            }, {}, false);

            if (messageReply.status === "True") {
              setLoader(true)
              setTimeout(() => window.close(), 4000);
            } else {
              setResponse(true);
              setLoader(true)
              setTimeout(() => window.close(), 4000);
            }
          } else {
            setResponse(true);
            setLoader(true)
            setTimeout(() => window.close(), 4000);
          }
        } else {
          setResponse(true);
          setLoader(true)
          setTimeout(() => window.close(), 4000);
        }
      } catch (error) {
        setResponse(true);
        setLoader(true)
        setTimeout(() => window.close(), 4000);
      }
    };
    if(!massage){
      setMassage(true)
      fetchData();
    }
    //eslint-disable-next-line
  }, [])

  return (
    <div style={{ height: '400px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div >
        {!loader ? <div> <RootLoader />
        </div> :
          <div>
            {!response ? <div style={{ textAlign: 'center' }}>
              <p style={{ color: 'green', fontWeight: 'bold', fontSize: '30px' }}>
                Response submitted successfully!
              </p>
            </div> :
              <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'red', fontWeight: 'bold', fontSize: '30px' }}>
                  Response not submitted!
                </p>
              </div>
            }
          </div>}
      </div>
    </div>
  );
};

export default EventEmailRespond;