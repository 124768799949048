import { isLoading, isLoaded, consolidatedToDoTo, consolidatedToDoBy, allTasks, consolidatedSubToDoBy, consolidatedSubToDoTo, consolidatedCalendarToDoTo, consolidatedCompleted, consolidatedSubCompleted, consolidatedCalCompleted, priorityTasks } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { updateUserStory } from "./updateUserStory";
import { Client_URL } from "../../../Common/config";


export async function getConsolidatedTodoTo(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_todo_to",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedToDoTo(response.data))
    }
    else {
      dispatch(consolidatedToDoTo([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

const completeTask = async (task, getUser) => {
  try {
    await API.post("manage_userstories.php", {
      action: "update",
      task_id: task.story_id,
      crop: getUser.corp,
      task_status: 100,
      task_status_desc: "completed",
      task_complete_status: 1,
      empId: getUser.empId,
      projectId: getUser.projectId,
    }, {}, false);

  } catch (error) {
    console.log(error)
  }
}

const filterToDeleteEvents = async (deleteEvents, getUser, APP_NAME) => {
  try {
    const deletePromises = deleteEvents.map(async item => {
      const response = await API.post("calendarDash.php", {
        action: "Delete",
        current: item,
        corp_code: item.project_name,
        projectId: item.ProjectName,
        fullName: getUser.fullName,
        userName: getUser.userName,
        url: Client_URL,
        appName: APP_NAME,
        deleteType: "auto_delete"
      }, {}, false);
      if (response.status === "True" && response.task_details?.length > 0) {
        response.task_details.forEach(async (task) => {
          if (task.active_status === "1") {
            await completeTask(task, getUser)
          }
        });
      }

    });

    await Promise.all(deletePromises);
  } catch (error) {
    console.error('Error deleting some events:', error);
  }
};

export async function getEventCalendarDash(getUser, APP_NAME) {
  try {
    var response = await API.post("calendarDash.php", {
      action: "GetEventCalendarDash",
      // corp_code: getUser.corp,
      // projectId: getUser.projectId,
      userName: getUser.userName,
      url: Client_URL,
      appName: APP_NAME,
    }, {}, false);
    if (response.status === 'True') {
      //for automatically delete the past events
      if (response.data.length > 0) {
        // eslint-disable-next-line
        const deleteEvents = response.data.filter(item => {
          if (new Date(item.EndTime) < new Date()) {
            return item
          }
        })
        if (deleteEvents.length > 0) {
          await filterToDeleteEvents(deleteEvents, getUser, APP_NAME)
        }
      }
    }
  } catch (error) {
    console.log(error)
    Alert('error', error.message);
  }
}

const filterToCompleteTasks = async (activities, getUser, completedItems, handleClose, TASKS_WARNING, ROADBLOCK_WARNING, MAINTASK) => {
  // eslint-disable-next-line 
  const completePromises = completedItems.map(item => {
    let data = { task_id: item.task_id, story_id: item.story_id, task_type: item.task_type, project_name: item.project_name, main_task_id: item.main_task_id, story_type: item.story_type, us_id: item.us_id, story_title: item.story_title, blocked_id: item.blocked_id, userStoryId: item.userStoryId, calendarId: item?.calendar_id };
    let message = `This meeting, ${MAINTASK} is completed`;
    updateUserStory(activities, getUser, data.story_id, handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING, data.story_type, data.us_id, data.main_task_id, MAINTASK, data.calendarId)
  });
  try {
    await Promise.all(completePromises);
    handleClose()
  } catch (error) {
    console.error('Error deleting some events:', error);
  }
}

export async function getConsolidatedCalendarToDoTo(activities, dispatch, getUser, handleClose, TASKS_WARNING, ROADBLOCK_WARNING, MAINTASK) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_cal_todo_to",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedCalendarToDoTo(response.data))
      if (response.data.length > 0) {
        // eslint-disable-next-line 
        const completedItems = response.data.filter(val => {
          if (val.calendar_id !== null && val.calendar_id !== undefined) {
            if ((new Date(val.endtime) < new Date()) && val.task_count === 0) {
              return val
            }
          }
        })
        if (completedItems.length > 0) {
          await filterToCompleteTasks(activities, getUser, completedItems, handleClose, TASKS_WARNING, ROADBLOCK_WARNING, MAINTASK)
        }
      }
    }
    else {
      dispatch(consolidatedCalendarToDoTo([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedTodoBy(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_todo_by",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedToDoBy(response.data))
    }
    else {
      dispatch(consolidatedToDoBy([]))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedSubTodoTo(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_sub_todo_to",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedSubToDoTo(response.data))
    }
    else {
      dispatch(consolidatedSubToDoTo([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedSubTodoBy(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_sub_todo_by",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedSubToDoBy(response.data))
    }
    else {
      dispatch(consolidatedSubToDoBy([]))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}

export async function getAllTasks(dispatch, getUser, searchValue) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "mytasker_all_tasks",
      "searchValue": searchValue,
      "role": getUser.role

    }, {}, false);
    if (response.status === 'True') {
      dispatch(allTasks(response.data))
      dispatch(isLoaded());
    }
    else {
      dispatch(allTasks([]))
      dispatch(isLoaded());
    }
  } catch (error) {
    Alert("error", error.message);
    dispatch(isLoaded());
  }

}

export async function getArchiveAllTasks(dispatch, getUser, searchValue) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "archive_all_tasks",
      "searchValue": searchValue,
      "role": getUser.role,
      "projectId": getUser.projectId,

    }, {}, false);
    if (response.status === 'True') {
      dispatch(allTasks(response.data))
      dispatch(isLoaded());
    }
    else {
      dispatch(allTasks([]))
      dispatch(isLoaded());
    }
  } catch (error) {
    Alert("error", error.message);
    dispatch(isLoaded());
  }

}

export async function getConsolidatedCompleted(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_completed",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedCompleted(response.data))
      dispatch(isLoaded());

    }
    else {
      dispatch(consolidatedCompleted([]))
      dispatch(isLoaded());

    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedCalCompleted(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_calendar_completed",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedCalCompleted(response.data))
      dispatch(isLoaded());

    }
    else {
      dispatch(consolidatedCalCompleted([]))
      dispatch(isLoaded());

    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedSubCompleted(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_sub_completed",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(consolidatedSubCompleted(response.data))
      dispatch(isLoaded());

    }
    else {
      dispatch(consolidatedSubCompleted([]))
      dispatch(isLoaded());

    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}

export async function getPriorityTasks(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "projectId": getUser.projectId,
      "action": "priority_all_tasks",
    }, {}, false);
    if (response.status === 'True') {
      dispatch(priorityTasks(response.data))
    }
    else {
      dispatch(priorityTasks([]))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}