import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
import { getUsers } from './Services/getUsers';
import { tasksReducer, initialState } from './tasksReducer';
import Select from 'react-select';
import RootLoader from '../Loader/RootLoader';
import { useWebProperties } from '../webProperties';
import { getProjects } from './Services/getProjects';
import { addMainTaskToKanban } from './Services/addMainTaskToKanban';
import getAiTaskDetails from './Services/getAiTaskDetails';
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import { priorityLevelsArray } from '../commonUsage';
// import EdiText from 'react-editext'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function AddMainTaskToKanban(props) {

    const [state, dispatch] = useReducer(tasksReducer, initialState);
    const getUser = useSelector(state => state.auth);
    const userRole = getUser?.user?.role;
    const activities = useSelector(state => state.landingReducer.userActivities)
    const classNames = useStyles();
    const [manualValue, setManualValue] = useState(null);
    const [defaultModule, setDefaultModule] = useState(null);
    const [defaultUser, setDefaultUser] = useState(null);
    const [selectedStoryValue, setSelectedStoryValue] = useState({ value: '2', label: '2 hrs' });
    const [selectedPriorityValue, setSelectedPriorityValue] = useState({ value: '2', label: '2' });
    const { NEWMAINTASK, MAINTASKNAME, PROJECT, MAINTASK_DESCRIPTION, STORY_POINTS, KANBAN,
        SELECT_SQUAD_MEMBER, ACCEPTANCE_CRITERIA, DEFINITION_OF_DONE, PRIORITY_LEVEL, MAINTASK_ADD, MAINTASK, AI_ENABLE, AI_MODEL } = useWebProperties();
    useEffect(() => {
        getUsers(dispatch, getUser.user);
        getProjects(dispatch, getUser.user);
        setManualValue(null)
        dispatch(actions.storySelected('2'))
        dispatch(actions.prioritySelected('2'))
        // eslint-disable-next-line
    }, [])
    const [isLoading, setIsLoading] = useState(false);
    var userDetails = [];
    var storyPoints = [];
    var priorityLevels = [];
    var epicsList = [];
    // var epicsList = [];
    var storyPointsArray = [{ id: '1', story: '1', hrs: '1hr' }, { id: '2', story: '2', hrs: '2hrs' }, { id: '3', story: '3', hrs: '3hrs' }, { id: '5', story: '5', hrs: '5hrs' }, { id: '8', story: '8', hrs: '8hrs' }, { id: '13', story: '13', hrs: '13hrs' }, { id: "Other", story: "Other", hrs: 'hrs' }]

    storyPointsArray.map((storyPointsArray) => {
        return (
            storyPoints.push({ 'value': storyPointsArray.id, 'label': `${storyPointsArray.story}-${storyPointsArray.hrs}` })
        );
    })
    priorityLevelsArray.map((priorityLevelsArray) => {
        return (
            priorityLevels.push({ 'value': priorityLevelsArray.id, 'label': priorityLevelsArray.priority })
        );
    })
    state.users.map((users, index) => {
        return (
            users.workingStatus === "Active" ?
                userDetails.push({ 'value': users.id, 'label': users.name, 'device_id': users.device_id, 'from': 'user', customId: `user-id-${index}` }) : null
        );
    })
    state.projects.map((epics) => {
        return (
            epicsList.push({
                'value': epics.idea_id, 'label': epics.idea_title
            })
        );
    })
    // module 
    useEffect(() => {
        if (state.projects.length > 0) {
            let epicArray = state.projects.map((epic) => {
                return {
                    'value': epic.idea_id,
                    'label': epic.idea_title
                };
            });
            if (epicArray.length > 0) {
                let defaultEpic = epicArray.filter(item => {
                    return item.label === 'Default Module'
                })
                setDefaultModule(defaultEpic[0]);
                dispatch(actions.epicSelected(defaultEpic[0]?.value));
            }
        }
    }, [state.projects, dispatch]);

    // users
    useEffect(() => {
        if (state.users.length > 0) {
            let userArray = state.users.map((user) => {
                return (
                    user.workingStatus === "Active" ?
                        { 'value': user.id, 'label': user.name, 'device_id': user.device_id, 'from': 'user', email: user.userName } : null
                );
            });

            // Assuming `getUser` and `dispatch` are available from props or context
            const currentUserEmail = getUser.user.userName;
            let array = userArray.filter(item => item && item.email === currentUserEmail);

            if (array.length > 0) {
                setDefaultUser(array[0]);
                dispatch(actions.userSelected(array[0].value));
            }
        }
    }, [state.users, getUser.user.userName, dispatch]);


    if (defaultModule) {
        state.epicSelected = defaultModule.value;
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    {state.isLoading ? <RootLoader /> : <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px' }}>
                                <div class="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{NEWMAINTASK}</h5>
                                    <div style={{ display: 'flex' }}>
                                        {state.isLoading ? <RootLoader /> :
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {/* {props.combine_tasks !== true &&
                                                    <>
                                                        <Link to={{ pathname: '/templateView' }} ><button type="button" class="btn btn-outline-primary" style={{ borderRadius: '20px' }} >
                                                            Use Template
                                                        </button></Link>&nbsp;&nbsp;
                                                    </>
                                                } */}
                                                {/* this add related to create support */}
                                                <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }}
                                                    onClick={(event) => {
                                                        props.combine_tasks !== true ?
                                                            addMainTaskToKanban(activities, props.data, state, dispatch, getUser.user, props.handleClose, MAINTASK_ADD, MAINTASK, KANBAN, props)
                                                            :
                                                            addMainTaskToKanban(activities, props.data.activeSprint, state, dispatch, getUser.user, props.handleClose, MAINTASK_ADD, MAINTASK, KANBAN, props)
                                                    }}>Add</button>&nbsp;&nbsp;
                                            </div>
                                        }
                                        <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                    </div>
                                </div>
                                <div className="modal-body overflow-auto" style={{ height: 500 }}>
                                    {
                                        AI_ENABLE === '1' ?
                                            <div class="input-group mb-3" style={{ position: "relative" }}>

                                                <input type="text" class="form-control" style={{ borderRadius: '25px', padding: '8px', paddingRight: "45px" }} placeholder="Type to generate using AI" aria-label="Recipient's username" aria-describedby="button-addon2"
                                                    value={state.aitaskinput.value}
                                                    onChange={(event) => dispatch(actions.aitaskinput(event.target.value))}
                                                />
                                                <button type="button" class="btn btn-outline-secondary btn-sm"
                                                    style={{ borderRadius: '10000px', position: "absolute", right: "5px", padding: "3px", marginTop: '6px', marginRight: '3px', zIndex: '500' }}
                                                    onClick={() => getAiTaskDetails(state.aitaskinput.value, dispatch, setIsLoading, getUser, activities["AI to generate task details"], AI_MODEL)}
                                                    disabled={state.aitaskinput.value.length === 0 || isLoading}
                                                >
                                                    {!isLoading ? <ArrowUpwardRoundedIcon fontSize={"medium"} backgroundColor={"grey"} /> :
                                                        (<div class="spinner-border text-primary" role="status">
                                                            <span class="visually-hidden"></span>
                                                        </div>)
                                                    }

                                                </button>
                                            </div>
                                            :
                                            null
                                    }

                                    <div class="form-group" style={{ height: 'auto' }}>
                                        <label for="recipient-name" class="col-form-label pt-0" style={{ width: '150px' }}>{MAINTASKNAME}<span style={{ color: "red" }} >*</span></label>
                                        <input type="text" class="form-control" id="title" name="title" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                            value={state.taskTitle.value}
                                            onChange={(event) => dispatch(actions.taskTitle(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskTitle.errorStatus ? (state.taskTitle.errormessage).toLowerCase() : ""}</span>
                                        {/*  + MAINTASKNAME */}
                                    </div>

                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="recipient-name" class="col-form-label pt-2" style={{ width: '100px' }}>{MAINTASK_DESCRIPTION}<span style={{ color: "red" }} >*</span></label>

                                        <textarea class="form-control" id="description" name="description" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '1px solid blackpx', borderLeft: '1px solid black', borderRight: '1px solid black', height: '70px', }}
                                            value={state.taskDescription.value}
                                            placeholder="As a [person],____ I [want to],____ [so that]____"
                                            onChange={(event) => dispatch(actions.taskDescription(event.target.value))} />
                                        <span style={{ color: "gray", fontSize: '12px' }}>{state.taskDescription.errorStatus ? state.taskDescription.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{ACCEPTANCE_CRITERIA}/{DEFINITION_OF_DONE}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="criteria" name="criteria" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '70px' }}
                                            value={state.acceptanceCriteria.value}
                                            onChange={(event) => dispatch(actions.acceptanceCriteria(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.acceptanceCriteria.errorStatus ? state.acceptanceCriteria.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px", display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr' }}>
                                        <div>
                                            <label for="epic" className="col-form-label pt-2" style={{ width: '100px' }}>{PROJECT} Name</label>

                                            <Select
                                                className="form-control "
                                                placeholder={`Select ${PROJECT}`}
                                                value={defaultModule}
                                                maxMenuHeight={130}
                                                onChange={(selectedOption) => {
                                                    dispatch(actions.epicSelected(selectedOption.value))
                                                    setDefaultModule(selectedOption);
                                                }}
                                                options={epicsList}
                                            />
                                        </div>
                                        <div>
                                            <label for="user" className="col-form-label pt-2" style={{ width: '180px' }}>{SELECT_SQUAD_MEMBER}</label>
                                            <Select
                                                className="form-control"
                                                placeholder={` ${SELECT_SQUAD_MEMBER}`}
                                                maxMenuHeight={130}
                                                value={defaultUser}
                                                onChange={(selectedOption) => {
                                                    dispatch(actions.userSelected(selectedOption.value))
                                                    dispatch(actions.userDeviceSelected(selectedOption.device_id))
                                                    setDefaultUser(selectedOption);
                                                }}
                                                options={userDetails.filter((user) => (userRole === 'Limited Access Contributor' ? user.value === defaultUser.value : user))}
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px", display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr' }}>
                                        <div>
                                            <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{STORY_POINTS}</label>
                                            <Select
                                                className="form-control"
                                                placeholder={`Select ${STORY_POINTS}`}
                                                value={selectedStoryValue}
                                                maxMenuHeight={110}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption.value === 'Other') {
                                                        setManualValue("Other");
                                                        setSelectedStoryValue(selectedOption)
                                                    } else {
                                                        setManualValue(null);
                                                        dispatch(actions.storySelected(selectedOption.value))
                                                        setSelectedStoryValue(selectedOption)
                                                    }
                                                }}
                                                options={storyPoints}
                                            />
                                        </div>
                                        <div>
                                            {/* drop down  for priorityLevel[S.S]  */}
                                            <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{PRIORITY_LEVEL}</label>
                                            <Select
                                                className="form-control"
                                                placeholder={`Select ${PRIORITY_LEVEL}`}
                                                value={selectedPriorityValue}
                                                maxMenuHeight={110}
                                                onChange={(selectedOption) => {
                                                    dispatch(actions.prioritySelected(selectedOption.value))
                                                    setSelectedPriorityValue(selectedOption)
                                                }}
                                                options={priorityLevels}
                                            />
                                        </div>
                                        {manualValue === 'Other' && (
                                            <>
                                                <input
                                                    className="form-control "
                                                    type="number"
                                                    onChange={(e) => dispatch(actions.storySelected(e.target.value))}
                                                    placeholder={`Enter ${STORY_POINTS} manually`}
                                                />
                                                <br />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div style={{ height: 20 }}></div>
                                {/* <div class="modal-footer">
                                    {state.isLoading ? <RootLoader /> :
                                     
                                            <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }}
                                                onClick={(event) => { addMainTaskToKanban(props.data, state, dispatch, getUser.user, props.handleClose, MAINTASK_ADD, MAINTASK,KANBAN) }}>Add</button>
                                            
                                    }
                                </div> */}

                            </div>
                        </div>
                    </div>}
                </Fade>
            </Modal>
        </div>
    );
}