import {
  isLoaded,
  isLoading,
  todo,
  doing,
  todoFilter,
  doingFilter,
  groupTodo,
  groupDoing,
  kanban_todo,
  kanban_doing,
  kanban_todo_filter,
  kanban_doing_filter,
} from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";

//For ToDo and Doing Subtask List based on task active status
export async function getToDoUserStories(dispatch, getUser, moduleId, refresh, type) {
  // console.log(moduleId,getUser.corp)
  if (refresh) { dispatch(isLoading()); }
  try {
    var response = await API.post(
      "get_user_stories.php",
      {
        crop: getUser.corp,
        moduleId: moduleId,
        action: "pending",
        projectId: getUser.projectId,
        userType: getUser.role,
        empId: getUser.empId,
      },
      {},
      false
    );
    var toDoList = [];
    var doingList = [];
    var groupTodoList = [];
    var groupDoingList = [];

    response.data.filter(item => {
      return item.story_type !== "group"
    }).map((pending) => {
      return (pending.activeStatus === "0" || pending.activeStatus === "2")
        ? toDoList.push(pending)
        : pending.activeStatus === "1"
          ? doingList.push(pending)
          : null;
    });
    response.data.filter(item => {
      return item.story_type === "group"
    }).map((pending) => {
      return (pending.activeStatus === "0" || pending.activeStatus === "2")
        ? groupTodoList.push(pending)
        : pending.activeStatus === "1"
          ? groupDoingList.push(pending)
          : null;
    });
    if (type === 'kanban') {
      store.dispatch(kanban_todo(toDoList));
      store.dispatch(kanban_doing(doingList));
      store.dispatch(kanban_todo_filter(toDoList));
      store.dispatch(kanban_doing_filter(doingList));
    } else {
      store.dispatch(todo(toDoList));
      store.dispatch(doing(doingList));
      store.dispatch(groupTodo(groupTodoList));
      store.dispatch(groupDoing(groupDoingList));
      store.dispatch(todoFilter(toDoList));
      store.dispatch(doingFilter(doingList));
    }
  } catch (error) {
    console.log(error);
  }

  dispatch(isLoaded());
}
